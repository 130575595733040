<template>
  <div>
    <b-sidebar id="sidebar-category-edit" :visible="!!value" @hidden="clearCategory()">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('cancellation_policy.category.' + value) }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <validation-observer #default="{ invalid, validate }" ref="category-observer">
          <b-form ref="category-form" class="p-2" @submit.prevent="validate().then(submitCategory)">
            <b-row>
              <b-col cols="12" class="mt-2 mb-2">
                <b-form-group :label="$t('navigation.categories')" label-for="categories">
                  <category-tree-select id="categories" v-model="categories" :lang="$store.state.appConfig.lang" disable-branch-nodes />
                </b-form-group>
              </b-col>
            </b-row>
            <CancellationPolicyCategoryConditions v-model="editedCategory.conditions" />

            <div class="d-flex p-2 mt-2 justify-content-center">
              <b-row align-v="center" align-h="end">
                <b-col>
                  <b-button class="mr-1" type="submit" :disabled="invalid || !categories.length">
                    {{ $t('action.save') }}
                  </b-button>
                  <b-button type="reset" variant="outline-secondary" class="mr-1" @click.prevent="resetCategory()">{{ $t('action.reset') }} </b-button>
                  <b-button type="button" variant="outline-secondary" @click="hide">
                    <font-awesome-icon icon="ban" class="mr-50" />
                    {{ $t('action.cancel') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import CategoryTreeSelect from '@/components/CategoryTreeSelect.vue'
import CancellationPolicyCategoryConditions from './CancellationPolicyCategoryConditions.vue'

export default {
  name: 'CancellationPolicyCategory',

  components: {
    CategoryTreeSelect,
    CancellationPolicyCategoryConditions,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    categoryCp: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      editedCategory: {},
      oldCategory: {},
      categories: [],
    }
  },

  watch: {
    value(val) {
      this.editedCategory = this._cloneDeep(this.categoryCp)
      this.oldCategory = this._cloneDeep(this.categoryCp)
      if (val === 'edit') {
        this.categories = [
          {
            id: this.editedCategory.categoryId,
            name: this.editedCategory.categoryName,
            children: [],
          },
        ]
      } else if (!val) {
        this.categories = []
      }
    },
  },

  methods: {
    resetCategory() {
      this.editedCategory = this._cloneDeep(this.oldCategory)
      this.$refs['category-observer'].reset()
    },

    clearCategory() {
      this.editedCategory = {}
      this.oldCategory = {}
      this.$emit('input', null)
      this.$refs['category-observer'].reset()
    },

    submitCategory() {
      if (this.categories.length) {
        this.$emit('update:category', {
          categoryCP: this.editedCategory,
          categories: this.categories.map(cat => ({ categoryId: cat.id, categoryName: cat.name })),
        })
        this.clearCategory()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cat-list {
  max-height: 255px;
  overflow: auto;
}
</style>
